import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import gsap from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTheme } from "@emotion/react";

const Svg = styled.svg`
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const AnimatedStepsMobile = () => {
  const theme = useTheme();
  const svgRef = useRef(null);
  const rectangleRef = useRef(null);
  const step1Step = useRef(null);
  const step1Title = useRef(null);
  const step1Text = useRef(null);
  const step2Step = useRef(null);
  const step2Title = useRef(null);
  const step2Text = useRef(null);
  const step3Step = useRef(null);
  const step3Title = useRef(null);
  const step3Text = useRef(null);
  const step4Step = useRef(null);
  const step4Title = useRef(null);
  const step4Text = useRef(null);
  const redDot = useRef(null);
  const blueDot = useRef(null);
  const arrow1 = useRef(null);
  const arrow2 = useRef(null);
  const dot3 = useRef(null);
  const dot4 = useRef(null);
  const chainContainer = useRef(null);
  const chainLeft = useRef(null);
  const chainRight = useRef(null);
  const line1 = useRef(null);
  const line2 = useRef(null);
  const line3 = useRef(null);
  const brandStrategy = useRef(null);
  const businessStrategy = useRef(null);
  const totalStrategy = useRef(null);

  const run = () => {
    const LABEL = "master";
    const tl = gsap
      .timeline({
        scrollTrigger: {
          id: "animatedStepsMobile",
          trigger: svgRef.current,
          start: "top top",
          // end: "+=1500",
          // markers: true,
          pin: svgRef.current,
          scrub: true,
        },
      })
      .addLabel(LABEL);
    gsap.set(rectangleRef.current, { fill: theme.colors.offWhite });
    gsap.set(line1.current, {
      strokeDasharray: line1.current.getTotalLength(),
      strokeDashoffset: line1.current.getTotalLength(),
    });
    gsap.set(line2.current, {
      strokeDasharray: line2.current.getTotalLength(),
      strokeDashoffset: line2.current.getTotalLength(),
    });
    gsap.set(line3.current, {
      strokeDasharray: line3.current.getTotalLength(),
      strokeDashoffset: line3.current.getTotalLength(),
    });
    tl.add(part1()).add(part2(), ">").add(part3());
  };

  const part1 = () => {
    const tl = gsap.timeline();
    const LABEL = "part1";
    tl.add(LABEL)
      .to(
        line1.current,
        {
          strokeDashoffset: line1.current.getTotalLength() * 2,
          ease: "linear",
        },
        LABEL
      )
      .to(
        chainContainer.current,
        { stroke: theme.colors.darkGold, duration: 0.2 },
        LABEL
      )
      .to(chainLeft.current, { x: 0.386 }, LABEL)
      .to(chainRight.current, { x: 15.614 }, LABEL)
      .to(arrow1.current, { autoAlpha: 1, duration: 0 }, `${LABEL}+=0.21`)
      .to(redDot.current, { autoAlpha: 1, duration: 0 }, `${LABEL}+=0.275`)
      .to(blueDot.current, { autoAlpha: 1, duration: 0 });
    return tl;
  };

  const part2 = () => {
    const tl = gsap.timeline();
    const LABEL = "part2";
    tl.add(LABEL)
      .to(
        [step1Step.current, step1Title.current, step1Text.current],
        { autoAlpha: 0, duration: 0.1 },
        LABEL
      )
      .to(
        [step2Step.current, step2Title.current, step2Text.current],
        { autoAlpha: 1, duration: 0.1 },
        LABEL
      )
      .to(
        line2.current,
        {
          strokeDashoffset: line2.current.getTotalLength() * 2,
          ease: "linear",
        },
        LABEL
      )
      .to(arrow2.current, { autoAlpha: 1, duration: 0 }, `${LABEL}+=0.175`);
    return tl;
  };

  const part3 = () => {
    const tl = gsap.timeline();
    const START = "part3";
    const STEP3 = `${START}+=0.061`;
    const STEP4 = `${START}+=0.266`;
    tl.add(START)
      .to(
        line3.current,
        {
          strokeDashoffset: 0,
          ease: "linear",
        },
        START
      )
      .to(
        rectangleRef.current,
        {
          fill: theme.colors.darkestPurple,
          duration: 0.12,
        },
        START
      )
      .to(
        `.pin-spacer-animatedStepsMobile`,
        {
          backgroundColor: theme.colors.darkestPurple,
          duration: 0.12,
        },
        START
      )
      .to(
        [step2Step.current, step2Title.current],
        { fill: theme.colors.lightTeal, duration: 0.12 },
        START
      )
      .to(dot3.current, { autoAlpha: 1, duration: 0 }, STEP3)
      .to(
        [brandStrategy.current, businessStrategy.current],
        { autoAlpha: 0, duration: 0.2 },
        STEP3
      )
      .to(totalStrategy.current, { autoAlpha: 1, duration: 0.2 }, STEP3)
      .to(chainContainer.current, { y: 98.15, duration: 0.2 }, STEP3)
      .to(
        [step2Step.current, step2Title.current, step2Text.current],
        { autoAlpha: 0, duration: 0.1 },
        STEP3
      )
      .to(
        [step3Step.current, step3Title.current, step3Text.current],
        { autoAlpha: 1, duration: 0.1 },
        STEP3
      )
      .to(chainLeft.current, { x: 15.614, duration: 0.1 }, STEP4)
      .to(chainRight.current, { x: 0.386, duration: 0.1 }, STEP4)
      .to(
        [step3Step.current, step3Title.current, step3Text.current],
        { autoAlpha: 0, duration: 0.1 },
        STEP4
      )
      .to(
        [step4Step.current, step4Title.current, step4Text.current],
        { autoAlpha: 1, duration: 0.1 },
        STEP4
      )
      .to(dot4.current, { autoAlpha: 1, duration: 0 }, STEP4);
    return tl;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }
    run();
    return () => {
      ScrollTrigger.getById("animatedStepsMobile").kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <style>
        {`
        .pin-spacer-animatedStepsMobile{
          background-color: ${theme.colors.offWhite};
        }
      `}
      </style>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="375"
        viewBox={`0 0 375 667`}
        ref={svgRef}
      >
        <g id="Partnership_Mobile_1" data-name="Partnership-Mobile–1">
          <g id="Group_469" data-name="Group 469" transform="translate(0 -41)">
            <rect
              id="Rectangle_554"
              data-name="Rectangle 554"
              width="402.371"
              height={667}
              transform="translate(-13.32 41.318)"
              ref={rectangleRef}
            />
            <text
              id="Our_partnership_over_time"
              data-name="Our partnership over time"
              transform="translate(32 92.997)"
              fill={theme.colors.darkGold}
              fontSize="16"
              fontFamily={theme.fonts.condensedMedium}
              letterSpacing="0.15em"
            >
              <tspan x="0" y="16">
                OUR PARTNERSHIP
              </tspan>
              <tspan x="0" y="41">
                OVER TIME
              </tspan>
            </text>
            <g
              id="Group_419"
              data-name="Group 419"
              transform="translate(162.994 230.518)"
            >
              <circle
                id="Ellipse_36"
                data-name="Ellipse 36"
                cx="84.5"
                cy="84.5"
                r="84.5"
                transform="translate(0.006 0.482)"
                fill={theme.colors.lightPeach}
              />
              <text
                id="Brand_Strategy"
                data-name="Brand Strategy"
                transform="translate(83.733 38.15)"
                fill={theme.colors.darkGold}
                fontSize="15"
                fontFamily={theme.fonts.regular}
                ref={brandStrategy}
              >
                <tspan x="-22.868" y="0">
                  Brand
                </tspan>
                <tspan x="-29.4" y="18">
                  Strategy
                </tspan>
              </text>
              <text
                id="Business_Strategy"
                data-name="Business Strategy"
                transform="translate(83.709 122.645)"
                fill={theme.colors.darkGold}
                fontSize="15"
                fontFamily={theme.fonts.regular}
                ref={businessStrategy}
              >
                <tspan x="-33.307" y="0">
                  Business
                </tspan>
                <tspan x="-29.4" y="18">
                  Strategy
                </tspan>
              </text>
              <text
                transform="translate(83.733 68.15)"
                fill={theme.colors.darkGold}
                fontSize="15"
                fontFamily={theme.fonts.regular}
                opacity={0}
                ref={totalStrategy}
              >
                <tspan x="-22.868" y="0">
                  A Total
                </tspan>
                <tspan x="-26.4" y="18">
                  Strategy
                </tspan>
              </text>
              <g
                id="Group_346"
                data-name="Group 346"
                transform="translate(64.392 73.246)"
                stroke={theme.colors.darkPeach}
                ref={chainContainer}
              >
                <g
                  id="Rectangle_118"
                  data-name="Rectangle 118"
                  transform="translate(-15.386 0.237)"
                  fill="none"
                  strokeWidth="2"
                  ref={chainLeft}
                >
                  <rect width="25" height="15" rx="7.5" stroke="none" />
                  <rect
                    x="1"
                    y="1"
                    width="23"
                    height="13"
                    rx="6.5"
                    fill="none"
                  />
                </g>
                <g
                  id="Rectangle_119"
                  data-name="Rectangle 119"
                  transform="translate(30.614 0.237)"
                  fill="none"
                  strokeWidth="2"
                  ref={chainRight}
                >
                  <rect width="25" height="15" rx="7.5" stroke="none" />
                  <rect
                    x="1"
                    y="1"
                    width="23"
                    height="13"
                    rx="6.5"
                    fill="none"
                  />
                </g>
              </g>
            </g>
            <text
              transform="translate(32 475)"
              fill={theme.colors.darkRed}
              fontSize="40"
              fontFamily={theme.fonts.medium}
              ref={step1Step}
            >
              <tspan x="0" y="0">
                Step 1:
              </tspan>
            </text>
            <text
              transform="translate(32 499.687)"
              fill={theme.colors.darkRed}
              fontSize="22"
              fontFamily={theme.fonts.light}
              ref={step1Title}
            >
              <tspan x="0" y="18">
                Elevate current marketing
              </tspan>
              <tspan x="0" y="47">
                performance.
              </tspan>
            </text>
            <text
              transform="translate(32 570.687)"
              fill={theme.colors.darkGold}
              fontSize="18"
              fontFamily={theme.fonts.light}
              ref={step1Text}
            >
              <tspan x="0" y="18">
                We analyze the current marketing
              </tspan>
              <tspan x="0" y="40">
                environment to identify opportunities
              </tspan>
              <tspan x="0" y="62">
                to improve short-term marketing
              </tspan>
              <tspan x="0" y="84">
                performance.
              </tspan>
            </text>
            <text
              transform="translate(32 475)"
              fill={theme.colors.darkRed}
              fontSize="40"
              fontFamily={theme.fonts.medium}
              opacity={0}
              ref={step2Step}
            >
              <tspan x="0" y="0">
                Step 2:
              </tspan>
            </text>
            <text
              transform="translate(32 499.687)"
              fill={theme.colors.darkRed}
              fontSize="18"
              fontFamily={theme.fonts.light}
              opacity={0}
              ref={step2Title}
            >
              <tspan x="0" y="18">
                Evaluate to determine the approach.
              </tspan>
            </text>
            <text
              transform="translate(32 540.687)"
              fill={theme.colors.darkGold}
              fontSize="18"
              fontFamily={theme.fonts.light}
              opacity={0}
              ref={step2Text}
            >
              <tspan x="0" y="18">
                We investigate and identify the
              </tspan>
              <tspan x="0" y="40">
                long-term strategic direction for
              </tspan>
              <tspan x="0" y="62">
                your business based on research
              </tspan>
              <tspan x="0" y="84">
                and insights.
              </tspan>
            </text>
            <text
              transform="translate(32 475)"
              fill={theme.colors.lightTeal}
              fontSize="40"
              fontFamily={theme.fonts.medium}
              opacity={0}
              ref={step3Step}
            >
              <tspan x="0" y="0">
                Step 3:
              </tspan>
            </text>
            <text
              transform="translate(32 499.687)"
              fill={theme.colors.lightTeal}
              fontSize="18"
              fontFamily={theme.fonts.light}
              opacity={0}
              ref={step3Title}
            >
              <tspan x="0" y="18">
                Point of transformation.
              </tspan>
            </text>
            <text
              transform="translate(32 540.687)"
              fill={theme.colors.darkGold}
              fontSize="18"
              fontFamily={theme.fonts.light}
              opacity={0}
              ref={step3Text}
            >
              <tspan x="0" y="18">
                Adopt, align and launch new brand
              </tspan>
              <tspan x="0" y="40">
                and marketing strategies to build
              </tspan>
              <tspan x="0" y="62">
                a cohesive brand experience.
              </tspan>
            </text>
            <text
              transform="translate(32 475)"
              fill={theme.colors.lightTeal}
              fontSize="40"
              fontFamily={theme.fonts.medium}
              opacity={0}
              ref={step4Step}
            >
              <tspan x="0" y="0">
                Step 4:
              </tspan>
            </text>
            <text
              transform="translate(32 499.687)"
              fill={theme.colors.lightTeal}
              fontSize="18"
              fontFamily={theme.fonts.light}
              opacity={0}
              ref={step4Title}
            >
              <tspan x="0" y="18">
                Iterate and build.
              </tspan>
            </text>
            <text
              transform="translate(32 540.687)"
              fill={theme.colors.darkGold}
              fontSize="18"
              fontFamily={theme.fonts.light}
              opacity={0}
              ref={step4Text}
            >
              <tspan x="0" y="18">
                Move forward by reporting on tactics
              </tspan>
              <tspan x="0" y="40">
                and adapting the approach with new
              </tspan>
              <tspan x="0" y="62">
                insights in order to reach limitless
              </tspan>
              <tspan x="0" y="84">
                growth.
              </tspan>
            </text>
          </g>
          <g
            id="Group_420"
            data-name="Group 420"
            transform="matrix(0.998, 0.07, -0.07, 0.998, -75.416, -256.987)"
          >
            <g
              id="Group_418"
              data-name="Group 418"
              transform="translate(0 74.068) rotate(-7)"
            >
              <path
                id="Path_619"
                data-name="Path 619"
                d="M270.421,0C256,5.507,242.979,14.506,231.549,25.936c-19.775,19.775-.025-.027-.025-.027L0,257.432"
                transform="translate(0 381.244)"
                fill="none"
                stroke={theme.colors.darkGold}
                strokeMiterlimit="10"
                strokeWidth="3"
                ref={line1}
              />
              <path
                id="Path_620"
                data-name="Path 620"
                d="M34.326,198.986A99.493,99.493,0,1,0,34.326,0,93.081,93.081,0,0,0,0,6.293"
                transform="translate(270.338 374.624)"
                fill="none"
                stroke={theme.colors.darkGold}
                strokeMiterlimit="10"
                strokeWidth="3"
                ref={line2}
              />
              <path
                id="Path_621"
                data-name="Path 621"
                d="M113.141,573.55A113.139,113.139,0,0,1,0,460.411C0,427.4,14.21,399.5,36.7,377.016s-.03-.029-.03-.029L414.932,0"
                transform="translate(192.833 0)"
                fill="none"
                stroke={theme.colors.darkGold}
                strokeMiterlimit="10"
                strokeWidth="3"
                ref={line3}
              />
            </g>
            <path
              id="Path_624-4"
              data-name="Path 624"
              d="M286.2,432.51v-15h15"
              transform="translate(560.19 241.652) rotate(83)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeMiterlimit="10"
              strokeWidth="3"
              opacity={0}
              ref={arrow1}
            />
            <path
              id="Path_624-2"
              data-name="Path 624"
              d="M286.2,432.51v-15h15"
              transform="translate(716.802 853.816) rotate(180)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeMiterlimit="10"
              strokeWidth="3"
              opacity={0}
              ref={arrow2}
            />

            <g
              id="diagram-dot-2"
              data-name="diagram-dot"
              transform="translate(209.726 525.302) rotate(-7)"
            >
              <ellipse
                cx="6"
                cy="6"
                rx="6"
                ry="6"
                fill={theme.colors.darkRed}
                opacity={0}
                ref={redDot}
              />
            </g>
            <g
              id="diagram-dot"
              transform="translate(309.194 413.28) rotate(-7)"
            >
              <ellipse
                cx="6"
                cy="6"
                rx="6"
                ry="6"
                fill={theme.colors.darkRed}
                opacity={0}
                ref={blueDot}
              />
            </g>

            <g id="dot3" transform="translate(272.19 566.729)">
              <ellipse
                cx="6"
                cy="6"
                rx="6"
                ry="6"
                fill={theme.colors.lightTeal}
                opacity={0}
                ref={dot3}
              />
            </g>
            <g id="dot4" transform="translate(358.427 298.947)">
              <ellipse
                cx="6"
                cy="6"
                rx="6"
                ry="6"
                fill={theme.colors.lightTeal}
                opacity={0}
                ref={dot4}
              />
            </g>
          </g>
        </g>
      </Svg>
    </>
  );
};

export default AnimatedStepsMobile;

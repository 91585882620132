import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../../components/Descriptor";
import Image from "../../components/image";
import InnerContainer from "../../components/InnerContainer";

const ItemizedExplainerContainer = styled.div`
  padding: 4rem 1.5rem;
  background-color: ${props => props.theme.colors.lightPeach};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 9rem 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

const DescriptorBox = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-width: 17rem;
    width: 17rem;
    margin-right: 10rem;
  }
`;

const ItemizedExplainerComponent = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 99rem;
    /* margin-left: 27.8571%;
    margin-right: 12.7142%;
    width: 59.4285%; */
  }
`;

const ItemizedExplainerTitle = styled.h2`
  font-size: 3.1rem;
  font-family: ${props => props.theme.fonts.medium};
  line-height: 38px;
  margin-bottom: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 4.8rem;
    margin-bottom: 2rem;
  }
  /* margin: 3rem 0 1.5rem 0; */
`;

const ItemizedExplainerSubtitle = styled.h3`
  font-size: 2.3rem;
  font-family: ${props => props.theme.fonts.regular};
  line-height: 35px;
  color: #1a1a1a;
  margin-bottom: 4.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.5rem;
    margin-bottom: 9rem;
  }
`;

const ItemizedExplainerListContainer = styled.div``;

const DescriptorImage = styled(Image)`
  height: 5.45rem;
  width: 5.45rem;
  margin: 3rem 0;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const ListTitle = styled.h4`
  font-size: 2.5rem;
  font-family: ${props => props.theme.fonts.medium};
  letter-spacing: 0.75px;
  line-height: 32px;
  margin-bottom: 4rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 3.1rem;
    line-height: 38px;
  }
`;

const ListBox = styled.div`
  margin-bottom: 4rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;

const Textbox = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 3.7rem;
    width: 76.4423%;
  }
`;

const ItemTitle = styled.h4`
  font-size: 2.3rem;
  font-family: ${props => props.theme.fonts.regular};
  line-height: 32px;
  letter-spacing: 0.69px;
  margin: 1.5rem 0 1rem 0;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.2rem;
    line-height: 36px;
    margin: 0 0 1rem 0;
  }
`;

const ItemImage = styled(Image)`
  width: 5.45rem;
  height: 5.45rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 5px;
    width: 12.15rem;
    height: 12.15rem;
  }
`;

const ItemText = styled.p`
  font-size: 1.8rem;
  font-family: ${props => props.theme.fonts.regular};
  line-height: 29px;
  color: #1a1a1a;
`;

const ItemizedExplainerNoIcons = props => {
  return (
    <ItemizedExplainerContainer>
      <InnerContainer>
        <FlexContainer>
          <DescriptorBox>
            <Descriptor
              title={props.descriptor}
              color={props.descriptorColor}
              alignment={props.underlineAlignment}
              align="center"
            />
            <DescriptorImage image={props.image} />
          </DescriptorBox>
          <ItemizedExplainerComponent>
            <ItemizedExplainerTitle
              css={css`
                color: ${props.titleColor};
              `}
            >
              {props.title}
            </ItemizedExplainerTitle>
            <ItemizedExplainerSubtitle>
              {props.subtitle}
            </ItemizedExplainerSubtitle>
            <ItemizedExplainerListContainer>
              <ListTitle
                css={css`
                  color: ${props.repeater.repeaterTitleColor};
                `}
              >
                {props.repeater.repeaterTitle}
              </ListTitle>
              {props.repeater.repeaterItems.map(item => (
                <ListBox key={item.title}>
                  {item.image && <ItemImage image={item.image} />}
                  <Textbox
                    css={css`
                      ${item.image ? "" : "margin-left: 0 !important"};
                    `}
                  >
                    <ItemTitle
                      css={css`
                        color: ${props.repeater.repeaterItemTitleColor};
                      `}
                    >
                      {item.title}
                    </ItemTitle>
                    <ItemText>{item.paragraph}</ItemText>
                  </Textbox>
                </ListBox>
              ))}
            </ItemizedExplainerListContainer>
          </ItemizedExplainerComponent>
        </FlexContainer>
      </InnerContainer>
    </ItemizedExplainerContainer>
  );
};

export default ItemizedExplainerNoIcons;

import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const Svg = styled.svg`
  max-width: 100%;
  width: 100%;
  height: auto;
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: block;
  }
`;

const AnimatedStepsDesktop = () => {
  const theme = useTheme();
  const svgRef = useRef(null);
  const rectangleRef = useRef(null);
  const circleRef = useRef(null);
  const line1 = useRef(null);
  const line2 = useRef(null);
  const line3 = useRef(null);
  const dot1 = useRef(null);
  const dot2 = useRef(null);
  const dot3 = useRef(null);
  const dot4 = useRef(null);
  const arrow1 = useRef(null);
  const arrow2 = useRef(null);
  const chainContainer = useRef(null);
  const chainLeft = useRef(null);
  const chainRight = useRef(null);
  const brandBusinessStrategyTextContainer = useRef(null);
  const totalStrategyTextContainer = useRef(null);
  const step1Step = useRef(null);
  const step1Title = useRef(null);
  const step1Text = useRef(null);
  const step2Step = useRef(null);
  const step2Title = useRef(null);
  const step2Text = useRef(null);
  const step3Step = useRef(null);
  const step3Title = useRef(null);
  const step3Text = useRef(null);
  const step4Step = useRef(null);
  const step4Title = useRef(null);
  const step4Text = useRef(null);

  const run = () => {
    const tl = gsap
      .timeline({
        scrollTrigger: {
          id: "animatedStepsDesktop",
          trigger: svgRef.current,
          start: "top top",
          end: "+=1500",
          // markers: true,
          pin: svgRef.current,
          scrub: true,
        },
      })
      .addLabel("master");
    tl.add(part1()).add(part2()).add(part3());
  };

  const part1 = () => {
    const tl = gsap.timeline();
    const LABEL = "part1";
    window[LABEL] = tl;
    gsap.set(line1.current, {
      strokeDasharray: Math.ceil(line1.current.getTotalLength()),
      strokeDashoffset: Math.ceil(line1.current.getTotalLength()),
    });
    tl.add(LABEL)
      .to(
        line1.current,
        {
          strokeDashoffset: line1.current.getTotalLength() * 2,
          ease: "linear",
        },
        LABEL
      )
      .to(
        chainLeft.current,
        {
          x: 20.417,
        },
        `${LABEL}`
      )
      .to(
        chainLeft.current,
        {
          stroke: theme.colors.darkGold,
          duration: 0.2,
        },
        `${LABEL}`
      )
      .to(
        chainRight.current,
        {
          x: 47.417,
        },
        `${LABEL}`
      )
      .to(
        chainRight.current,
        {
          stroke: theme.colors.darkGold,
          duration: 0.2,
        },
        `${LABEL}`
      )
      .to(
        dot1.current,
        {
          autoAlpha: 1,
          duration: 0,
        },
        `${LABEL}+=0.065`
      )
      .to(
        arrow1.current,
        {
          autoAlpha: 1,
          duration: 0,
        },
        `${LABEL}+=0.2`
      );
    return tl;
  };

  const part2 = () => {
    const tl = gsap.timeline();
    const LABEL = "part2";
    gsap.set(line2.current, {
      strokeDasharray: Math.ceil(line2.current.getTotalLength()),
      strokeDashoffset: Math.ceil(line2.current.getTotalLength()),
    });
    tl.add(LABEL)
      .to(
        dot2.current,
        {
          autoAlpha: 1,
          duration: 0,
        },
        LABEL
      )
      .to(
        [step1Step.current, step1Text.current, step1Title.current],
        {
          autoAlpha: 0,
          duration: 0.15,
        },
        LABEL
      )
      .to(
        [step2Step.current, step2Text.current, step2Title.current],
        {
          autoAlpha: 1,
          duration: 0.15,
        },
        LABEL
      )
      .to(
        line2.current,
        {
          strokeDashoffset: line2.current.getTotalLength() * 2,
          ease: "linear",
        },
        LABEL
      )
      .to(
        arrow2.current,
        {
          autoAlpha: 1,
          duration: 0,
        },
        `${LABEL}+=0.225`
      );
    return tl;
  };

  const part3 = () => {
    const tl = gsap.timeline();
    const START = "part3";
    const STEP3 = `${START}+=0.12`;
    const STEP4 = `${START}+=0.365`;
    window[START] = tl;
    gsap.set(line3.current, {
      strokeDasharray: Math.ceil(line3.current.getTotalLength()),
      strokeDashoffset: Math.ceil(line3.current.getTotalLength()),
    });
    tl.add(START)
      .to(
        line3.current,
        {
          strokeDashoffset: 0,
          ease: "linear",
        },
        START
      )
      .to(
        rectangleRef.current,
        {
          fill: theme.colors.darkestPurple,
          duration: 0.12,
        },
        START
      )
      .to(
        [step2Step.current, step2Title.current],
        { fill: theme.colors.lightTeal, duration: 0.12 },
        START
      )
      .to(
        brandBusinessStrategyTextContainer.current,
        { autoAlpha: 0, duration: 0.2 },
        STEP3
      )
      .to(
        totalStrategyTextContainer.current,
        { autoAlpha: 1, duration: 0.2 },
        STEP3
      )
      .to(
        chainContainer.current,
        {
          y: 468.224,
          duration: 0.2,
        },
        STEP3
      )
      .to(
        dot3.current,
        {
          autoAlpha: 1,
          duration: 0,
        },
        STEP3
      )
      .to(
        [step2Step.current, step2Text.current, step2Title.current],
        {
          autoAlpha: 0,
          duration: 0.05,
        },
        STEP3
      )
      .to(
        [step3Step.current, step3Text.current, step3Title.current],
        {
          autoAlpha: 1,
          duration: 0.05,
        },
        STEP3
      )
      .to(
        dot4.current,
        {
          autoAlpha: 1,
          duration: 0,
        },
        STEP4
      )
      .to(
        chainLeft.current,
        {
          x: 47.417,
        },
        STEP4
      )
      .to(chainRight.current, { x: 20.417 }, STEP4)
      .to(
        [step3Step.current, step3Text.current, step3Title.current],
        {
          autoAlpha: 0,
          duration: 0.15,
        },
        STEP4
      )
      .to(
        [step4Step.current, step4Text.current, step4Title.current],
        {
          autoAlpha: 1,
          duration: 0.15,
        },
        STEP4
      );
    return tl;
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }
    run();
    return () => {
      ScrollTrigger.getById("animatedStepsDesktop").kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <style>
        {`
          .pin-spacer-animatedStepsDesktop{
            background-color: ${theme.colors.offWhite};
          }
        `}
      </style>

      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="1398"
        height="753"
        viewBox="0 0 1398 753"
        ref={svgRef}
      >
        <rect
          id="Rectangle_892"
          data-name="Rectangle 892"
          width="1400.555"
          height="753.211"
          transform="translate(0 0)"
          fill={theme.colors.offWhite}
          ref={rectangleRef}
        />
        <text
          id="Our_partnership_over_time"
          data-name="Our partnership over time"
          transform="translate(1085.5539999999999 246.50199999999998)"
          fill={theme.colors.darkGold}
          fontSize="16"
          fontFamily={theme.fonts.condensedMedium}
          letterSpacing="0.15em"
        >
          <tspan x="-137.941" y="0">
            OUR PARTNERSHIP OVER TIME
          </tspan>
        </text>

        <text
          id="Step_1:"
          data-name="Step 1:"
          transform="translate(959.554 330.505)"
          fill={theme.colors.darkRed}
          fontSize="48"
          fontFamily={theme.fonts.medium}
          ref={step1Step}
        >
          <tspan x="0" y="0">
            Step 1:
          </tspan>
        </text>
        <text
          transform="translate(959.919 359.98400000000004)"
          fill={theme.colors.darkRed}
          fontSize="28"
          fontFamily={theme.fonts.light}
          ref={step1Title}
        >
          <tspan x="0" y="30">
            Elevate current marketing
          </tspan>
          <tspan x="0" y="66">
            performance.
          </tspan>
        </text>
        <text
          transform="translate(959.919 455)"
          fill={theme.colors.darkGold}
          fontSize="20"
          fontFamily={theme.fonts.light}
          ref={step1Text}
        >
          <tspan x="0" y="22">
            We analyze the current marketing
          </tspan>
          <tspan x="0" y="47">
            environment to identify opportunities to
          </tspan>
          <tspan x="0" y="72">
            improve short-term marketing
          </tspan>
          <tspan x="0" y="97">
            performance.
          </tspan>
        </text>
        <text
          id="Step_2:"
          data-name="Step 2:"
          transform="translate(959.554 330.505)"
          fill={theme.colors.darkRed}
          fontSize="48"
          fontFamily={theme.fonts.medium}
          ref={step2Step}
          opacity={0}
        >
          <tspan x="0" y="0">
            Step 2:
          </tspan>
        </text>
        <text
          transform="translate(959.919 359.98400000000004)"
          fill={theme.colors.darkRed}
          fontSize="28"
          fontFamily={theme.fonts.light}
          ref={step2Title}
          opacity={0}
        >
          <tspan x="0" y="30">
            Evaluate to determine
          </tspan>
          <tspan x="0" y="66">
            the approach.
          </tspan>
        </text>
        <text
          transform="translate(959.919 455)"
          fill={theme.colors.darkGold}
          fontSize="20"
          fontFamily={theme.fonts.light}
          ref={step2Text}
          opacity={0}
        >
          <tspan x="0" y="22">
            We investigate and identify the
          </tspan>
          <tspan x="0" y="47">
            long-term strategic direction for
          </tspan>
          <tspan x="0" y="72">
            your business based on research
          </tspan>
          <tspan x="0" y="97">
            and insights.
          </tspan>
        </text>
        <text
          id="Step_3:"
          data-name="Step 3:"
          transform="translate(959.554 330.505)"
          fill={theme.colors.lightTeal}
          fontSize="48"
          fontFamily={theme.fonts.medium}
          ref={step3Step}
          opacity={0}
        >
          <tspan x="0" y="0">
            Step 3:
          </tspan>
        </text>
        <text
          transform="translate(959.919 359.98400000000004)"
          fill={theme.colors.lightTeal}
          fontSize="28"
          fontFamily={theme.fonts.light}
          ref={step3Title}
          opacity={0}
        >
          <tspan x="0" y="30">
            Point of transformation.
          </tspan>
        </text>
        <text
          transform="translate(959.919 419.98400000000004)"
          fill={theme.colors.darkGold}
          fontSize="20"
          fontFamily={theme.fonts.light}
          ref={step3Text}
          opacity={0}
        >
          <tspan x="0" y="22">
            Adopt, align and launch new brand and
          </tspan>
          <tspan x="0" y="47">
            marketing strategies to build a cohesive
          </tspan>
          <tspan x="0" y="72">
            brand experience.
          </tspan>
        </text>
        <text
          id="Step_4:"
          data-name="Step 4:"
          transform="translate(959.554 330.505)"
          fill={theme.colors.lightTeal}
          fontSize="48"
          fontFamily={theme.fonts.medium}
          ref={step4Step}
          opacity={0}
        >
          <tspan x="0" y="0">
            Step 4:
          </tspan>
        </text>
        <text
          id="Iterate_and_build."
          data-name="Iterate and build."
          transform="translate(959.919 359.98400000000004)"
          fill={theme.colors.lightTeal}
          fontSize="28"
          fontFamily={theme.fonts.light}
          ref={step4Title}
          opacity={0}
        >
          <tspan x="0" y="30">
            Iterate and build.
          </tspan>
        </text>
        <text
          id="Move_forward_by_reporting_on_tactics_and_adapting_the_approach_with_new_insights_in_order_to_reach_limitless_growth."
          data-name="Move forward by reporting on tactics and adapting the approach with new insights in order to reach limitless growth."
          transform="translate(959.919 419.98400000000004)"
          fill={theme.colors.darkGold}
          fontSize="20"
          fontFamily={theme.fonts.light}
          ref={step4Text}
          opacity={0}
        >
          <tspan x="0" y="22">
            Move forward by reporting on tactics
          </tspan>
          <tspan x="0" y="47">
            and adapting the approach with new
          </tspan>
          <tspan x="0" y="72">
            insights in order to reach limitless
          </tspan>
          <tspan x="0" y="97">
            growth.
          </tspan>
        </text>

        <path
          id="Path_626-2"
          data-name="Path 626"
          d="M538.67,0c-28.73,10.97-54.663,28.895-77.432,51.663-39.391,39.391-.05-.053-.05-.053L0,512.8"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 154.742, 57.109999999999985)"
          fill="none"
          stroke={theme.colors.darkGold}
          strokeMiterlimit="10"
          strokeWidth="4"
          ref={line1}
        />
        <path
          id="Path_628-2"
          data-name="Path 628"
          d="M68.377,396.375c109.457,0,198.189-88.733,198.189-198.189S177.833,0,68.377,0A185.414,185.414,0,0,0,0,12.535"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 665.2800000000001, 228.89700000000002)"
          fill="none"
          stroke={theme.colors.darkGold}
          strokeMiterlimit="10"
          strokeWidth="4"
          ref={line2}
        />
        <path
          id="Path_638-2"
          data-name="Path 638"
          d="M225.373,1142.495A225.184,225.184,0,0,1,0,917.125C0,851.368,28.305,795.8,73.1,751s-.06-.057-.06-.057l403.3-401.931"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 775.433, -525.1419999999999)"
          fill="none"
          stroke={theme.colors.darkGold}
          strokeMiterlimit="10"
          strokeWidth="4"
          ref={line3}
        />
        <path
          id="Path_631-2"
          data-name="Path 631"
          d="M286.2,446.971V417.51h29.46"
          transform="matrix(-0.342, 0.94, -0.94, -0.342, 766.091, 276.29600000000005)"
          fill="none"
          stroke={theme.colors.darkGold}
          strokeMiterlimit="10"
          strokeWidth="4"
          opacity={0}
          ref={arrow1}
        />
        <path
          id="Path_630"
          data-name="Path 630"
          d="M286.2,446.971V417.51h29.46"
          transform="translate(766.784 933.762) rotate(-135)"
          fill="none"
          stroke={theme.colors.darkGold}
          strokeMiterlimit="10"
          strokeWidth="4"
          opacity={0}
          ref={arrow2}
        />
        <ellipse
          id="Ellipse_37"
          data-name="Ellipse 37"
          cx="172"
          cy="172.5"
          rx="172"
          ry="172.5"
          transform="translate(487.554 263.505)"
          fill={theme.colors.lightPeach}
          ref={circleRef}
        />
        <ellipse
          id="Ellipse_39-2"
          data-name="Ellipse 39"
          cx="10"
          cy="10"
          rx="10"
          ry="10"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 71.95400000000001, 481.22)"
          fill={theme.colors.darkRed}
          opacity={0}
          ref={dot1}
        />
        <ellipse
          id="Ellipse_38"
          data-name="Ellipse 38"
          cx="10"
          cy="10"
          rx="10"
          ry="10"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 656.495, 226.78)"
          fill={theme.colors.darkRed}
          opacity={0}
          ref={dot2}
        />
        <ellipse
          id="Ellipse_40"
          data-name="Ellipse 40"
          cx="10"
          cy="10"
          rx="10"
          ry="10"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 442.495, 407.7800000000001)"
          fill={theme.colors.lightTeal}
          opacity={0}
          ref={dot3}
        />
        <ellipse
          id="Ellipse_41"
          data-name="Ellipse 41"
          cx="10"
          cy="10"
          rx="10"
          ry="10"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 812.495, 83.78)"
          fill={theme.colors.lightTeal}
          opacity={0}
          ref={dot4}
        />
        <g
          transform="translate(11.333 -87.536)"
          ref={brandBusinessStrategyTextContainer}
        >
          <text
            id="Brand_Strategy"
            data-name="Brand Strategy"
            transform="translate(543.64 456.847)"
            fill={theme.colors.darkGold}
            fontSize="31"
            fontFamily={theme.fonts.regular}
          >
            <tspan x="5.072" y="33">
              Brand Strategy
            </tspan>
          </text>
          <text
            id="Business_Strategy"
            data-name="Business Strategy"
            transform="translate(510.75 559.054)"
            fill={theme.colors.darkGold}
            fontSize="31"
            fontFamily={theme.fonts.regular}
          >
            <tspan x="20.003" y="33">
              Business Strategy
            </tspan>
          </text>
        </g>
        <g
          xmlns="http://www.w3.org/2000/svg"
          transform="translate(11.333 -47.536)"
          opacity={0}
          ref={totalStrategyTextContainer}
        >
          <text
            id="A_Total_Strategy"
            data-name="A Total Strategy"
            transform="translate(543.64 424.847)"
            fill={theme.colors.darkGold}
            fontSize="31"
            fontFamily={theme.fonts.regular}
          >
            <tspan x="58.8" y="33">
              A Total
            </tspan>
            <tspan x="49.378" y="71">
              Strategy
            </tspan>
          </text>
        </g>
        <g
          id="Group_353"
          data-name="Group 353"
          transform="translate(602.25 428.224)"
          ref={chainContainer}
        >
          <g
            id="Rectangle_118"
            data-name="Rectangle 118"
            transform="translate(0.417 0.312)"
            fill="none"
            stroke={theme.colors.darkPeach}
            strokeWidth="4"
            ref={chainLeft}
          >
            <rect width="44" height="28" rx="14" stroke="none" />
            <rect x="2" y="2" width="40" height="24" rx="12" fill="none" />
          </g>
          <g
            id="Rectangle_119"
            data-name="Rectangle 119"
            transform="translate(67.417 0.312)"
            fill="none"
            stroke={theme.colors.darkPeach}
            strokeWidth="4"
            ref={chainRight}
          >
            <rect width="44" height="28" rx="14" stroke="none" />
            <rect x="2" y="2" width="40" height="24" rx="12" fill="none" />
          </g>
        </g>
      </Svg>
    </>
  );
};

export default AnimatedStepsDesktop;

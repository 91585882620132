import React from "react";
import ChainlinkDesktop from "./ChainlinkDesktop";
import ChainlinkMobile from "./ChainlinkMobile";

const AnimatedChainlink = () => {
  return (
    <>
      <ChainlinkDesktop />
      <ChainlinkMobile />
    </>
  );
};

export default AnimatedChainlink;

import React from "react";
import AnimatedStepsDesktop from "./AnimatedStepsDesktop";
import AnimatedStepsMobile from "./AnimatedStepsMobile";

const AnimatedSteps = () => {
  return (
    <>
      <AnimatedStepsDesktop />
      <AnimatedStepsMobile />
    </>
  );
};

export default AnimatedSteps;

import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import gsap from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { useTheme } from "@emotion/react";

const Svg = styled.svg`
  max-width: 100%;
  width: 100%;
  height: auto;
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: block;
  }
`;

const ChainlinkDesktop = () => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const leftCircle = useRef(null);
  const rightCircle = useRef(null);
  const leftTextContainerLarge = useRef(null);
  const leftTextContainerSmall = useRef(null);
  const leftRedTextBrandLarge = useRef(null);
  const leftRedTextStrategyLarge = useRef(null);
  const leftRedTextBrandSmall = useRef(null);
  const leftRedTextStrategySmall = useRef(null);
  const rightTextContainerLarge = useRef(null);
  const rightTextContainerSmall = useRef(null);
  const rightRedTextBusinessLarge = useRef(null);
  const rightRedTextStrategyLarge = useRef(null);
  const rightRedTextBusinessSmall = useRef(null);
  const rightRedTextStrategySmall = useRef(null);
  const yourCompanyText = useRef(null);
  const yourCompanyLine = useRef(null);
  const yourCustomersText = useRef(null);
  const yourCustomersLine = useRef(null);
  const chainlinkLeft = useRef(null);
  const chainlinkRight = useRef(null);
  const internalCapabilities = useRef(null);
  const performanceMarketing = useRef(null);
  const blueExperience = useRef(null);
  const blueMarketing = useRef(null);
  const leftOutroText = useRef(null);
  const rightOutroText = useRef(null);
  const leftLoop = useRef(null);
  const rightLoop = useRef(null);
  const leftTopArrow = useRef(null);
  const leftMiddleArrow = useRef(null);
  const leftBottomArrow = useRef(null);
  const rightTopArrow = useRef(null);
  const rightMiddleArrow = useRef(null);
  const rightBottomArrow = useRef(null);

  const runAnimation = () => {
    const tl = gsap
      .timeline({
        scrollTrigger: {
          id: "animatedChainlinkDesktop",
          trigger: containerRef.current,
          // prod start below
          start: "top top",
          end: "+=4000",
          //   markers: true,
          pin: containerRef.current,
          scrub: true,
        },
      })
      .addLabel("master");
    // run the entire timeline
    tl.add(intro())
      .add(introTextIn())
      .add(chainOut(), ">1")
      .add(outro(), ">0.75");
  };

  const intro = () => {
    const tl = gsap.timeline();
    const START = "start";

    tl.add(START)
      .to(
        leftCircle.current,
        {
          x: 234.224,
          y: 190.921,
          // onUpdate: function () {
          //   console.log(this);
          // },
        },
        START
      )
      .to(
        rightCircle.current,
        {
          x: 830.224,
          y: 190.921,
        },
        START
      )
      .to(
        leftTextContainerLarge.current,
        {
          x: 403.224,
          y: 349,
          autoAlpha: 1,
        },
        START
      )
      .to(
        rightTextContainerLarge.current,
        {
          x: 999.224,
          y: 349,
          autoAlpha: 1,
        },
        START
      )
      .to(
        yourCompanyText.current,
        {
          autoAlpha: 0,
          delay: 0.25,
        },
        START
      )
      .to(
        yourCompanyLine.current,
        {
          autoAlpha: 0,
          delay: 0.25,
        },
        START
      )
      .to(
        chainlinkLeft.current,
        {
          autoAlpha: 1,
        },
        `${START}+=0.5`
      )
      .to(
        chainlinkRight.current,
        {
          autoAlpha: 1,
        },
        `${START}+=0.5`
      );
    return tl;
  };
  const introTextIn = () => {
    const tl = gsap.timeline();
    tl.to(
      performanceMarketing.current,
      {
        autoAlpha: 1,
      },
      `intro`
    ).to(
      internalCapabilities.current,
      {
        autoAlpha: 1,
      },
      `intro+=0.5`
    );
    return tl;
  };
  const chainOut = () => {
    const tl = gsap.timeline();
    const CHAINOUT = "chainOut";
    tl.to(
      chainlinkLeft.current,
      {
        autoAlpha: 0,
      },
      CHAINOUT
    )
      .to(
        chainlinkRight.current,
        {
          autoAlpha: 0,
        },
        CHAINOUT
      )
      .to(
        performanceMarketing.current,
        {
          autoAlpha: 0,
        },
        CHAINOUT
      )
      .to(
        internalCapabilities.current,
        {
          autoAlpha: 0,
        },
        CHAINOUT
      )
      .to(
        yourCustomersText.current,
        {
          autoAlpha: 1,
        },
        `${CHAINOUT}+=0.5`
      )
      .to(
        yourCustomersLine.current,
        {
          autoAlpha: 1,
        },
        `${CHAINOUT}+=0.5`
      );
    return tl;
  };
  const outroTextLeft = () => {
    const tl = gsap.timeline();
    const LABEL = "outroTextLeft";
    tl.add(LABEL)
      .to(
        leftTextContainerLarge.current,
        {
          autoAlpha: 0,
          duration: 0.1,
        },
        LABEL
      )
      .to(
        leftTextContainerSmall.current,
        {
          autoAlpha: 1,
        },
        ">"
      )
      .to(blueExperience.current, { autoAlpha: 1 }, "<");
    return tl;
  };
  const outroLeft = () => {
    const tl = gsap.timeline();
    const LABEL = "outroLeft";
    gsap.set(leftLoop.current, {
      strokeDasharray: Math.ceil(leftLoop.current.getTotalLength()),
      strokeDashoffset: Math.ceil(leftLoop.current.getTotalLength()),
    });
    tl.add(LABEL)
      .add(outroTextLeft(), LABEL)
      .to(leftOutroText.current, { autoAlpha: 1 }, LABEL)
      .to(leftLoop.current, { strokeDashoffset: 0 }, LABEL)
      .to(
        leftTopArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.075`
      )
      .to(
        leftMiddleArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.146`
      )
      .to(
        leftBottomArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.24`
      );
    return tl;
  };
  const outroTextRight = () => {
    const tl = gsap.timeline();
    const LABEL = "outroTextRight";
    tl.add(LABEL)
      .to(
        rightTextContainerLarge.current,
        {
          autoAlpha: 0,
          duration: 0.1,
        },
        LABEL
      )
      .to(
        rightTextContainerSmall.current,
        {
          autoAlpha: 1,
        },
        ">"
      )
      .to(blueMarketing.current, { autoAlpha: 1 }, "<");
    return tl;
  };
  const outroRight = () => {
    const tl = gsap.timeline();
    const LABEL = "outroRight";
    gsap.set(rightLoop.current, {
      strokeDashoffset: Math.ceil(rightLoop.current.getTotalLength()),
      strokeDasharray: Math.ceil(rightLoop.current.getTotalLength()),
    });
    tl.add(LABEL)
      .add(outroTextRight(), LABEL)
      .to(rightOutroText.current, { autoAlpha: 1 }, LABEL)
      .to(rightLoop.current, { strokeDashoffset: 0 }, LABEL)
      .to(
        rightTopArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.075`
      )
      .to(
        rightMiddleArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.146`
      )
      .to(
        rightBottomArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.24`
      );
    return tl;
  };
  const outro = () => {
    const tl = gsap.timeline({
      // onUpdate: () => {
      //   console.log(tl.progress());
      // },
    });
    const OUTRO = "outro";
    tl.add(OUTRO)
      .to(
        yourCustomersText.current,
        {
          autoAlpha: 0,
          duration: 0.15,
        },
        OUTRO
      )
      .to(
        yourCustomersLine.current,
        {
          autoAlpha: 0,
          duration: 0.15,
        },
        OUTRO
      )
      .add(outroLeft())
      .add(outroRight());
    return tl;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(TextPlugin, ScrollTrigger);
    }
    runAnimation();
    return () => {
      ScrollTrigger.getById("animatedChainlinkDesktop").kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <style>
        {`
        .pin-spacer-animatedChainlinkDesktop{
          background: linear-gradient(to right, ${theme.colors.lightPeach} 0%, ${theme.colors.lightPeach} 50%, ${theme.colors.offWhite} 50%, ${theme.colors.offWhite});
        }
      `}
      </style>
      <Svg
        ref={containerRef}
        xmlns="http://www.w3.org/2000/svg"
        width="1398"
        height="748"
        viewBox="0 0 1398 748"
      >
        <defs>
          <clipPath id="clip-Artboard_12">
            <rect width="1398" height="748" />
          </clipPath>
        </defs>
        <g
          id="Artboard_12"
          data-name="Artboard – 12"
          clipPath="url(#clip-Artboard_12)"
        >
          <rect width="1398" height="748" fill="#fff" />
          <rect
            id="Rectangle_123"
            data-name="Rectangle 123"
            width="751.153"
            height="699"
            transform="translate(699) rotate(90)"
            fill="#f4edeb"
          />
          <rect
            id="Rectangle_124"
            data-name="Rectangle 124"
            width="751.153"
            height="699"
            transform="translate(1398) rotate(90)"
            fill="#fcf7f7"
          />
          <text
            id="Building_internal_capabilities"
            data-name="Building internal capabilities"
            transform="translate(390 624.906)"
            fill="#d38b5c"
            fontSize="26"
            fontFamily="GT America Light, GT America"
            fontWeight="300"
            opacity={0}
            ref={internalCapabilities}
          >
            <tspan x="-167.7" y="0">
              Building internal capabilities
            </tspan>
          </text>
          <text
            id="Performance_marketing"
            data-name="Performance marketing"
            transform="translate(992 624.906)"
            fill="#d38b5c"
            fontSize="26"
            fontFamily="GT America Light, GT America"
            fontWeight="300"
            opacity={0}
            ref={performanceMarketing}
          >
            <tspan x="-136.942" y="0">
              Performance marketing
            </tspan>
          </text>
          <g
            id="Rectangle_118"
            data-name="Rectangle 118"
            transform="translate(653.224 343)"
            fill="none"
            opacity={0}
            stroke="#d38b5c"
            strokeWidth="4"
            ref={chainlinkLeft}
          >
            <rect width="54" height="36" rx="18" stroke="none" />
            <rect x="2" y="2" width="50" height="32" rx="16" fill="none" />
          </g>
          <g
            id="Rectangle_119"
            data-name="Rectangle 119"
            transform="translate(690.224 343)"
            fill="none"
            opacity={0}
            stroke="#d38b5c"
            strokeWidth="4"
            ref={chainlinkRight}
          >
            <rect width="54" height="36" rx="18" stroke="none" />
            <rect x="2" y="2" width="50" height="32" rx="16" fill="none" />
          </g>
          <text
            id="Your_COMPANY"
            data-name="Your COMPANY"
            transform="translate(700.724 346.616)"
            fill="#d38b5c"
            fontSize="18"
            fontFamily="GT America, GT America"
            letterSpacing="0.15em"
            ref={yourCompanyText}
          >
            <tspan x="-31.662" y="0">
              YOUR
            </tspan>
            <tspan x="-51.786" y="25">
              COMPANY
            </tspan>
          </text>
          <line
            id="Line_90"
            data-name="Line 90"
            x2="63"
            transform="translate(667.724 387.119)"
            fill="none"
            stroke="#d38b5c"
            strokeWidth={1}
            ref={yourCompanyLine}
          />
          <text
            id="Your_Customers"
            data-name="Your Customers"
            transform="translate(700.724 331.997)"
            fill="#d38b5c"
            fontSize="18"
            fontFamily="GT America"
            letterSpacing="0.15em"
            opacity={0}
            ref={yourCustomersText}
          >
            <tspan x="-31.662" y="0">
              YOUR
            </tspan>
            <tspan x="-64.836" y="25">
              CUSTOMERS
            </tspan>
          </text>
          <line
            id="Line_90"
            data-name="Line 90"
            x2="63"
            transform="translate(667.724 372.5)"
            fill="none"
            stroke="#d38b5c"
            strokeWidth="1"
            opacity={0}
            ref={yourCustomersLine}
          />
          <ellipse
            id="Ellipse_21"
            data-name="Ellipse 21"
            cx="169.265"
            cy="169.265"
            rx="169.265"
            ry="169.265"
            transform="translate(179.224 190.54)"
            fill="#fcf7f7"
            ref={leftCircle}
          />
          <ellipse
            id="Ellipse_22"
            data-name="Ellipse 22"
            cx="169.265"
            cy="169.265"
            rx="169.265"
            ry="169.265"
            transform="translate(882.224 190.54)"
            fill="#f4edeb"
            ref={rightCircle}
          />
          <text
            id="Brand_Strategy_Desktop"
            data-name="Brand Strategy"
            transform="translate(348.224 348.619)"
            fill="#e81821"
            fontSize={37}
            opacity={0.2}
            fontFamily="GT America, GT America"
            ref={leftTextContainerLarge}
          >
            <tspan x="-50.227" y="0" ref={leftRedTextBrandLarge}>
              Brand
            </tspan>
            <tspan x="-70.022" y="45" ref={leftRedTextStrategyLarge}>
              Strategy
            </tspan>
          </text>
          <text
            id="Brand_Strategy_Desktop_2"
            data-name="Brand Strategy"
            transform="translate(403.224 389)"
            fill="#e81821"
            fontSize={16}
            opacity={0}
            letterSpacing={2.4}
            fontWeight={500}
            fontFamily="GT America Condensed Medium, GT America"
            ref={leftTextContainerSmall}
          >
            <tspan x="-70.022" y="0" ref={leftRedTextBrandSmall}>
              Brand
            </tspan>
            <tspan x="-12.022" y="0" ref={leftRedTextStrategySmall}>
              Strategy
            </tspan>
          </text>
          <text
            id="Business_Strategy_Desktop"
            data-name="Business Strategy"
            transform="translate(1051.224 348.619)"
            fill="#e81821"
            fontSize={37}
            opacity={0.2}
            fontFamily="GT America, GT America"
            ref={rightTextContainerLarge}
          >
            <tspan x="-75.924" y="0" ref={rightRedTextBusinessLarge}>
              Business
            </tspan>
            <tspan x="-70.022" y="45" ref={rightRedTextStrategyLarge}>
              Strategy
            </tspan>
          </text>
          <text
            id="Business_Strategy_Desktop_2"
            data-name="Business Strategy"
            transform="translate(999.224 389)"
            fill="#e81821"
            fontSize={16}
            opacity={0}
            letterSpacing={2.4}
            fontWeight={500}
            fontFamily="GT America Condensed Medium, GT America"
            ref={rightTextContainerSmall}
          >
            <tspan x="-76.924" y="0" ref={rightRedTextBusinessSmall}>
              Business
            </tspan>
            <tspan x="7.022" y="0" ref={rightRedTextStrategySmall}>
              Strategy
            </tspan>
          </text>
          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Experience"
            transform="translate(403.224 349)"
            fill="#1b104e"
            fontSize="37"
            fontFamily="GT America, GT America"
            opacity={0}
            ref={blueExperience}
          >
            <tspan x="-92.888" y="0">
              Experience
            </tspan>
          </text>
          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Marketing"
            transform="translate(999.224 347)"
            fill="#1b104e"
            fontSize="37"
            fontFamily="GT America, GT America"
            opacity={0}
            ref={blueMarketing}
          >
            <tspan x="-84.656" y="0">
              Marketing
            </tspan>
          </text>
          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Positive_brand_associations"
            data-name="Positive brand associations"
            transform="translate(390 624.906)"
            fill="#d38b5c"
            fontSize="26"
            fontFamily="GT America, GT America"
            opacity={0}
            ref={leftOutroText}
          >
            <tspan x="-160.979" y="0">
              Positive brand associations
            </tspan>
          </text>
          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Driven_to_connect"
            data-name="Driven to connect"
            transform="translate(992 624.906)"
            fill="#d38b5c"
            fontSize="26"
            fontFamily="GT America, GT America"
            opacity={0}
            ref={rightOutroText}
          >
            <tspan x="-103.987" y="0">
              Driven to connect
            </tspan>
          </text>
          <path
            id="Path_38"
            data-name="Path 38"
            d="M288.933,90.477,150.364-48.09C101.12-97.334,55.194-120.082-7.977-120.082c-116.135,0-210.283,94.148-210.283,210.283S-124.112,300.486-7.979,300.486c61.358,0,113.208-26.409,155-68.209s141.912-141.8,141.912-141.8"
            transform="translate(411.439 267.715)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            ref={leftLoop}
          />
          <path
            id="Path_39"
            data-name="Path 39"
            d="M288.933,90.477,150.364-48.09C101.12-97.334,55.194-120.082-7.977-120.082c-116.135,0-210.283,94.148-210.283,210.283S-124.112,300.486-7.979,300.486c61.358,0,113.208-26.409,155-68.209s141.912-141.8,141.912-141.8"
            transform="translate(989.561 267.715) scale(-1 1)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            ref={rightLoop}
          />
          <path
            id="Path_40"
            data-name="Path 40"
            d="M281.593,280.984l-16.79-16.79,16.79-16.79"
            transform="translate(123.416 -116.089)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            opacity={0}
            ref={leftTopArrow}
          />
          <path
            id="Path_41"
            data-name="Path 41"
            d="M210.607,331.684l-16.79,16.79-16.79-16.79"
            transform="translate(0 8.159)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            opacity={0}
            ref={leftMiddleArrow}
          />
          <path
            id="Path_42"
            data-name="Path 42"
            d="M258.947,416.39l16.79,16.79-16.79,16.79"
            transform="translate(123.416 135.038)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            opacity={0}
            ref={leftBottomArrow}
          />
          <path
            id="Path_43"
            data-name="Path 43"
            d="M521.3,416.3l-16.79,16.79,16.79,16.79"
            transform="translate(491.704 135.038)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            opacity={0}
            ref={rightBottomArrow}
          />
          <path
            id="Path_44"
            data-name="Path 44"
            d="M584.99,338.412l16.79,16.79,16.79-16.79"
            transform="translate(606 8.159)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            opacity={0}
            ref={rightMiddleArrow}
          />
          <path
            id="Path_45"
            data-name="Path 45"
            d="M509.364,280.857l16.791-16.79-16.791-16.79"
            transform="translate(491.704 -116.089)"
            fill="none"
            stroke="#d38b5c"
            strokeMiterlimit="10"
            strokeWidth="4"
            opacity={0}
            ref={rightTopArrow}
          />
        </g>
      </Svg>
    </>
  );
};

export default ChainlinkDesktop;

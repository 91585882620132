import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import gsap from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { useTheme } from "@emotion/react";

const Svg = styled.svg`
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const ChainlinkMobile = () => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const internalCapabilities = useRef(null);
  const performanceMarketing = useRef(null);
  const yourCompanyText = useRef(null);
  const yourCustomersText = useRef(null);
  const chainlinkTop = useRef(null);
  const chainlinkBottom = useRef(null);
  const textLine = useRef(null);
  const topOutroText = useRef(null);
  const bottomOutroText = useRef(null);
  const blueExperience = useRef(null);
  const blueMarketing = useRef(null);
  const topTextContainerLarge = useRef(null);
  const topRedTextBrandLarge = useRef(null);
  const topRedTextStrategyLarge = useRef(null);
  const bottomTextContainerLarge = useRef(null);
  const bottomRedTextBusinessLarge = useRef(null);
  const bottomRedTextStrategyLarge = useRef(null);
  const topTextContainerSmall = useRef(null);
  const topRedTextBrandSmall = useRef(null);
  const topRedTextStrategySmall = useRef(null);
  const bottomTextContainerSmall = useRef(null);
  const bottomRedTextBusinessSmall = useRef(null);
  const bottomRedTextStrategySmall = useRef(null);
  const topTopArrow = useRef(null);
  const topLeftArrow = useRef(null);
  const topRightArrow = useRef(null);
  const bottomBottomArrow = useRef(null);
  const bottomLeftArrow = useRef(null);
  const bottomRightArrow = useRef(null);
  const topLoop = useRef(null);
  const bottomLoop = useRef(null);

  const runAnimation = () => {
    const scroller = ScrollTrigger.create({
      id: "animatedChainlinkMobile",
      trigger: containerRef.current,
      // prod start below
      start: "top top",
      end: "+=1500",
      //   markers: true,
      pin: containerRef.current,
      scrub: true,
    });
    const tl = gsap
      .timeline({
        scrollTrigger: scroller.vars,
      })
      .addLabel("master");
    // run the entire timeline
    tl.add(intro())
      .add(introTextIn())
      .add(chainOut(), ">1")
      .add(outro(), ">0.75");
  };

  const intro = () => {
    const tl = gsap.timeline();
    const START = "start";
    tl.add(START)
      .to(
        yourCompanyText.current,
        {
          autoAlpha: 0,
          delay: 0.25,
        },
        START
      )
      .to(
        textLine.current,
        {
          autoAlpha: 0,
          delay: 0.25,
        },
        START
      )
      .to(
        chainlinkTop.current,
        {
          autoAlpha: 1,
        },
        `${START}+=0.5`
      )
      .to(
        chainlinkBottom.current,
        {
          autoAlpha: 1,
        },
        `${START}+=0.5`
      );
    return tl;
  };

  const introTextIn = () => {
    const tl = gsap.timeline();
    tl.to(
      performanceMarketing.current,
      {
        autoAlpha: 1,
      },
      `intro`
    ).to(
      internalCapabilities.current,
      {
        autoAlpha: 1,
      },
      `intro`
    );
    return tl;
  };

  const chainOut = () => {
    const tl = gsap.timeline();
    const CHAINOUT = "chainOut";
    tl.to(
      chainlinkTop.current,
      {
        autoAlpha: 0,
      },
      CHAINOUT
    )
      .to(
        chainlinkBottom.current,
        {
          autoAlpha: 0,
        },
        CHAINOUT
      )
      .to(
        performanceMarketing.current,
        {
          autoAlpha: 0,
        },
        CHAINOUT
      )
      .to(
        internalCapabilities.current,
        {
          autoAlpha: 0,
        },
        CHAINOUT
      )
      .to(
        yourCustomersText.current,
        {
          autoAlpha: 1,
        },
        `${CHAINOUT}+=0.5`
      )
      .to(
        textLine.current,
        {
          autoAlpha: 1,
        },
        `${CHAINOUT}+=0.5`
      );
    return tl;
  };

  const outroTextTop = () => {
    const tl = gsap.timeline();
    const LABEL = "outroTextTop";
    tl.add(LABEL)
      .to(
        topTextContainerLarge.current,
        {
          autoAlpha: 0,
          duration: 0.1,
        },
        LABEL
      )
      .to(
        topTextContainerSmall.current,
        {
          autoAlpha: 1,
        },
        ">"
      )
      .to(blueExperience.current, { autoAlpha: 1 }, "<");
    return tl;
  };

  const outroTop = () => {
    const tl = gsap.timeline();
    const LABEL = "outroTop";
    gsap.set(topLoop.current, {
      strokeDasharray: Math.ceil(topLoop.current.getTotalLength()),
      strokeDashoffset: Math.ceil(topLoop.current.getTotalLength()),
    });
    tl.add(LABEL)
      .add(outroTextTop(), LABEL)
      .to(topOutroText.current, { autoAlpha: 1 }, LABEL)
      .to(topLoop.current, { strokeDashoffset: 0 }, LABEL)
      .to(
        topRightArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.075`
      )
      .to(topTopArrow.current, { autoAlpha: 1, duration: 0 }, `${LABEL}+=0.146`)
      .to(
        topLeftArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.24`
      );
    return tl;
  };

  const outroTextBottom = () => {
    const tl = gsap.timeline();
    const LABEL = "outroTextBottom";
    tl.add(LABEL)
      .to(
        bottomTextContainerLarge.current,
        {
          autoAlpha: 0,
          duration: 0.1,
        },
        LABEL
      )
      .to(
        bottomTextContainerSmall.current,
        {
          autoAlpha: 1,
        },
        ">"
      )
      .to(blueMarketing.current, { autoAlpha: 1 }, "<");
    return tl;
  };

  const outroBottom = () => {
    const tl = gsap.timeline();
    const LABEL = "outroBottom";
    gsap.set(bottomLoop.current, {
      strokeDasharray: Math.ceil(bottomLoop.current.getTotalLength()),
      strokeDashoffset: Math.ceil(bottomLoop.current.getTotalLength()),
    });
    tl.add(LABEL)
      .add(outroTextBottom(), LABEL)
      .to(bottomOutroText.current, { autoAlpha: 1 }, LABEL)
      .to(
        bottomLoop.current,
        { strokeDashoffset: bottomLoop.current.getTotalLength() * 2 },
        LABEL
      )
      .to(
        bottomRightArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.075`
      )
      .to(
        bottomBottomArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.146`
      )
      .to(
        bottomLeftArrow.current,
        { autoAlpha: 1, duration: 0 },
        `${LABEL}+=0.24`
      );
    return tl;
  };

  const outro = () => {
    const tl = gsap.timeline({
      // onUpdate: () => {
      //   console.log(tl.progress());
      // },
    });
    const OUTRO = "outro";
    tl.add(OUTRO)
      .to(
        yourCustomersText.current,
        {
          autoAlpha: 0,
          duration: 0.15,
        },
        OUTRO
      )
      .to(
        textLine.current,
        {
          autoAlpha: 0,
          duration: 0.15,
        },
        OUTRO
      )
      .add(outroTop())
      .add(outroBottom());
    return tl;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(TextPlugin, ScrollTrigger);
    }
    runAnimation();
    return () => {
      ScrollTrigger.getById("animatedChainlinkMobile").kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <style>
        {`
        .pin-spacer-animatedChainlinkMobile{
          background: linear-gradient(${theme.colors.lightPeach} 0%, ${theme.colors.lightPeach} 29%, ${theme.colors.offWhite} 29%, ${theme.colors.offWhite});
        }
      `}
      </style>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="386.419"
        height={"627"}
        viewBox="0 0 386.419 627"
        ref={containerRef}
      >
        <g id="Group_470" data-name="Group 470" transform="translate(5 -0.254)">
          <g id="Group_466" data-name="Group 466" transform="translate(0 -30)">
            <rect
              id="Rectangle_895"
              data-name="Rectangle 895"
              width="386.419"
              height="314"
              transform="translate(381.419 344.254) rotate(180)"
              fill={theme.colors.lightPeach}
            />
            <rect
              id="Rectangle_706"
              data-name="Rectangle 706"
              width="386.419"
              height="313.454"
              transform="translate(381.419 657.254) rotate(180)"
              fill={theme.colors.offWhite}
            />
            <g
              id="Group_463"
              data-name="Group 463"
              transform="translate(103 116.158)"
            >
              <ellipse
                id="Ellipse_21"
                data-name="Ellipse 21"
                cx="85.673"
                cy="85.673"
                rx="85.673"
                ry="85.673"
                fill={theme.colors.offWhite}
              />
              <text
                id="Experience"
                transform="translate(84.177 82.841)"
                fill={theme.colors.darkestPurple}
                fontSize="18"
                fontFamily="GT America Medium, GT America"
                fontWeight="500"
                opacity={0}
                ref={blueExperience}
              >
                <tspan x="-46.629" y="0">
                  Experience
                </tspan>
              </text>
              <text
                id="Brand_Strategy_Mobile"
                data-name="Brand Strategy"
                transform="translate(84.177 78.841)"
                fill={theme.colors.darkRed}
                fontSize="18"
                fontFamily="GT America Condensed Medium, GT America"
                ref={topTextContainerLarge}
              >
                <tspan x="-23.26" y="0" ref={topRedTextBrandLarge}>
                  Brand
                </tspan>
                <tspan x="-32.66" y="22" ref={topRedTextStrategyLarge}>
                  Strategy
                </tspan>
              </text>
              <text
                id="Brand_Strategy_Mobile_2"
                data-name="Brand Strategy"
                transform="translate(113.177 100)"
                fill={theme.colors.darkRed}
                fontSize="8"
                fontFamily="GT America Condensed Medium, GT America"
                letterSpacing={`0.15em`}
                fontWeight={500}
                opacity={0}
                ref={topTextContainerSmall}
              >
                <tspan x="-63.022" y="0" ref={topRedTextBrandSmall}>
                  Brand
                </tspan>
                <tspan x="-33.022" y="0" ref={topRedTextStrategySmall}>
                  Strategy
                </tspan>
              </text>
            </g>
            <g
              id="Group_464"
              data-name="Group 464"
              transform="translate(102.823 443.178)"
            >
              <ellipse
                id="Ellipse_22"
                data-name="Ellipse 22"
                cx="85.673"
                cy="85.673"
                rx="85.673"
                ry="85.673"
                transform="translate(0.177 -44.178)"
                fill={theme.colors.lightPeach}
              />
              <text
                id="Marketing"
                transform="translate(84.354 38.822)"
                fill={theme.colors.darkestPurple}
                fontSize="18"
                fontFamily="GT America Medium, GT America"
                fontWeight="500"
                opacity={0}
                ref={blueMarketing}
              >
                <tspan x="-42.516" y="0">
                  Marketing
                </tspan>
              </text>
              <text
                id="Business_Strategy_Mobile"
                data-name="Business Strategy"
                transform="translate(85.177 37.822)"
                fill={theme.colors.darkRed}
                fontSize="17"
                fontFamily="GT America Condensed Medium, GT America"
                ref={bottomTextContainerLarge}
              >
                <tspan x="-31.817" y="0" ref={bottomRedTextBusinessLarge}>
                  Business
                </tspan>
                <tspan x="-30.846" y="20" ref={bottomRedTextStrategyLarge}>
                  Strategy
                </tspan>
              </text>
              <text
                id="Business_Strategy_Mobile_2"
                data-name="Business Strategy"
                transform="translate(113.177 57)"
                fill={theme.colors.darkRed}
                fontSize="8"
                fontFamily="GT America Condensed Medium, GT America"
                letterSpacing={`0.15em`}
                fontWeight={500}
                opacity={0}
                ref={bottomTextContainerSmall}
              >
                <tspan x="-68.924" y="0" ref={bottomRedTextBusinessSmall}>
                  Business
                </tspan>
                <tspan x="-24.978" y="0" ref={bottomRedTextStrategySmall}>
                  Strategy
                </tspan>
              </text>
            </g>
            <text
              id="Your_COMPANY"
              data-name="Your COMPANY"
              transform="translate(192.5 336.997)"
              fill={theme.colors.darkGold}
              fontSize="18"
              fontFamily="GT America Condensed Medium, GT America"
              letterSpacing="0.15em"
              ref={yourCompanyText}
            >
              <tspan x="-30.84" y="0">
                YOUR
              </tspan>
              <tspan x="-50.235" y="25">
                COMPANY
              </tspan>
            </text>
          </g>
          <g
            id="Group_465"
            data-name="Group 465"
            transform="translate(198.847 288.139) rotate(90)"
          >
            <g
              id="Rectangle_118"
              data-name="Rectangle 118"
              transform="translate(-0.139 -0.153)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeWidth="3"
              opacity={0}
              ref={chainlinkTop}
            >
              <rect width="32" height="21" rx="10.5" stroke="none" />
              <rect x="1.5" y="1.5" width="29" height="18" rx="9" fill="none" />
            </g>
            <g
              id="Rectangle_119"
              data-name="Rectangle 119"
              transform="translate(21.861 -0.153)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeWidth="3"
              opacity={0}
              ref={chainlinkBottom}
            >
              <rect width="30" height="21" rx="10.5" stroke="none" />
              <rect x="1.5" y="1.5" width="27" height="18" rx="9" fill="none" />
            </g>
          </g>

          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Building_internal_capabilities"
            data-name="Building internal capabilities"
            transform="translate(189 43.83)"
            fill={theme.colors.darkGold}
            fontSize="18"
            fontFamily="GT America Light, GT America"
            fontWeight="300"
            opacity={0}
            ref={internalCapabilities}
          >
            <tspan x="-116.1" y="0">
              Building internal capabilities
            </tspan>
          </text>
          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Positive_brand_associations"
            data-name="Positive brand associations"
            transform="translate(189 43.83)"
            fill={theme.colors.darkGold}
            fontSize="18"
            fontFamily="GT America Light, GT America"
            fontWeight="300"
            opacity={0}
            ref={topOutroText}
          >
            <tspan x="-110.907" y="0">
              Positive brand associations
            </tspan>
          </text>
          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Performance_marketing"
            data-name="Performance marketing"
            transform="translate(188 596.83)"
            fill={theme.colors.darkGold}
            fontSize="18"
            fontFamily="GT America Light, GT America"
            fontWeight="300"
            opacity={0}
            ref={performanceMarketing}
          >
            <tspan x="-94.806" y="0">
              Performance marketing
            </tspan>
          </text>
          <text
            xmlns="http://www.w3.org/2000/svg"
            id="Driven_to_connect"
            data-name="Driven to connect"
            transform="translate(189 596.83)"
            fill={theme.colors.darkGold}
            fontSize="18"
            fontFamily="GT America Light, GT America"
            fontWeight="300"
            opacity={0}
            ref={bottomOutroText}
          >
            <tspan x="-71.856" y="0">
              Driven to connect
            </tspan>
          </text>
          <text
            id="Your_Customers"
            data-name="Your Customers"
            transform="translate(187.5 306.997)"
            fill={theme.colors.darkGold}
            fontSize="18"
            fontFamily="GT America Condensed Medium, GT America"
            fontWeight="500"
            letterSpacing="0.15em"
            opacity={0}
            ref={yourCustomersText}
          >
            <tspan x="-27.513" y="0">
              YOUR
            </tspan>
            <tspan x="-56.412" y="25">
              CUSTOMERS
            </tspan>
          </text>
          <line
            x2="63"
            transform="translate(156.5 346.5)"
            fill="none"
            stroke={theme.colors.darkGold}
            strokeWidth="1"
            ref={textLine}
          />
          <g
            xmlns="http://www.w3.org/2000/svg"
            id="Group_468"
            data-name="Group 468"
            transform="translate(71.032 593.151) rotate(-90)"
          >
            <path
              id="Path_43-2"
              data-name="Path 43"
              d="M513.139,416.3l-8.625,8.625,8.625,8.625"
              transform="translate(-84.089 -416.442)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeMiterlimit="10"
              strokeWidth="3"
              opacity={0}
              ref={topLeftArrow}
            />
            <path
              id="Path_44-2"
              data-name="Path 44"
              d="M584.99,338.412l8.625,8.625,8.625-8.625"
              transform="translate(1123.246 462.424) rotate(180)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeMiterlimit="10"
              strokeWidth="3"
              opacity={0}
              ref={topTopArrow}
            />
            <path
              id="Path_635"
              data-name="Path 635"
              d="M509.364,264.527l8.625-8.625-8.625-8.625"
              transform="translate(-95.133 -31.277)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeMiterlimit="10"
              strokeWidth="3"
              opacity={0}
              ref={topRightArrow}
            />
            <path
              id="Path_38-2"
              data-name="Path 38"
              d="M250.669,108.163,189.361,36.982C164.064,11.686,140.472,0,108.022,0a108.022,108.022,0,0,0,0,216.044c31.519,0,58.155-13.566,79.623-35.039s63.026-72.842,63.026-72.842"
              transform="translate(529.812 224.589) rotate(180)"
              fill="none"
              stroke={theme.colors.darkGold}
              strokeMiterlimit="10"
              strokeWidth="3"
              ref={topLoop}
            />
          </g>
          <g
            xmlns="http://www.w3.org/2000/svg"
            id="Group_467"
            data-name="Group 467"
            transform="translate(304.138 34.752) rotate(90)"
          >
            <g id="Group_15" data-name="Group 15">
              <path
                id="Path_43"
                data-name="Path 43"
                d="M513.139,416.3l-8.625,8.625,8.625,8.625"
                transform="translate(-83.089 -200.442)"
                fill="none"
                stroke={theme.colors.darkGold}
                strokeMiterlimit="10"
                strokeWidth="3"
                opacity={0}
                ref={bottomLeftArrow}
              />
              <path
                id="Path_44"
                data-name="Path 44"
                d="M584.99,338.412l8.625,8.625,8.625-8.625"
                transform="translate(-63.983 -230.024)"
                fill="none"
                stroke={theme.colors.darkGold}
                strokeMiterlimit="10"
                strokeWidth="3"
                opacity={0}
                ref={bottomBottomArrow}
              />
              <path
                id="Path_45"
                data-name="Path 45"
                d="M509.364,264.527l8.625-8.625-8.625-8.625"
                transform="translate(-95.132 -247.277)"
                fill="none"
                stroke={theme.colors.darkGold}
                strokeMiterlimit="10"
                strokeWidth="3"
                opacity={0}
                ref={bottomRightArrow}
              />
              <path
                id="Path_38"
                data-name="Path 38"
                d="M250.669,108.163,189.361,36.982C164.064,11.686,140.472,0,108.022,0a108.022,108.022,0,0,0,0,216.044c31.519,0,58.155-13.566,79.623-35.039s63.026-72.842,63.026-72.842"
                transform="translate(529.812 224.589) rotate(180)"
                fill="none"
                stroke={theme.colors.darkGold}
                strokeMiterlimit="10"
                strokeWidth="3"
                ref={bottomLoop}
              />
            </g>
          </g>
        </g>
      </Svg>
    </>
  );
};

export default ChainlinkMobile;
